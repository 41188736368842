<svg
	xmlns="http://www.w3.org/2000/svg"
	class={$$props.class}
	style={$$props.style}
	fill={$$props.fille ?? 'none'}
	viewBox="0 0 24 24"
	stroke={$$props.stroke ?? 'currentColor'}
	stroke-width={$$props.strokeWidth ?? 2}
>
	<path
		stroke-linecap="round"
		stroke-linejoin="round"
		d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
	/>
</svg>
